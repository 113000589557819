@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Montserrat+Narrow:wght@700&family=Open+Sans&display=swap');

.no-scroll {
    overflow: hidden;
}

.header {
    background-color: white;
    color: #333;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 10%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    box-sizing: border-box;
   
}

.logo-container {
    flex-shrink: 0;
    height: 80px; 
    display: flex;
    align-items: center;
    
}
.logo {
    height: 110px; 
    width: auto;
    max-height: 110px;
    object-fit: contain;
}

.logo:hover {
    transform: scale(1.05); /* Efekt powiększenia */
}

.nav-menu {
    display: flex;
    align-items: center;
    gap: 2rem;
}
.nav-links {
    display: flex;
    align-items: center;
    gap: 2rem;
  
}

.nav-menu a {
    color: #333;
    text-decoration: none;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 1.1rem;
    padding: 0.5rem;
    transition: color 0.3s ease;
}

.nav-menu a:hover {
    color: #ff5722;
}

.nav-menu a::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 2px;
    background: #ff5722;
    transition: all 0.3s ease;
    transform: translateX(-50%);
}

.nav-menu a:hover::after {
    width: 100%;
}

.btn-primary {
    background-color: #ff5722;
    color: white;
    padding: 0.75rem 1.5rem;
    font-size: 1.1rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    white-space: nowrap;
}

.btn-primary:hover {
    background-color: #e64a19;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.btn-primary::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 5px;
    height: 5px;
    background: rgba(255, 255, 255, 0.5);
    opacity: 0;
    border-radius: 100%;
    transform: scale(1, 1) translate(-50%);
    transform-origin: 50% 50%;
}

.btn-primary:focus:not(:active)::after {
    animation: ripple 0.6s ease-out;
}

@keyframes ripple {
    0% {
        transform: scale(0, 0);
        opacity: 0.5;
    }
    100% {
        transform: scale(20, 20);
        opacity: 0;
    }
}

.mobile-menu-button {
    display: none;
    background: none;
    border: none;
    padding: 0.5rem;
    cursor: pointer;
    z-index: 1001;
}

.hamburger {
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding: 5px;
}

.hamburger .line {
    width: 35px;
    height: 2px;
    background-color: #333;
    transition: all 0.3s ease;
}

.hamburger.active .line:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
}

.hamburger.active .line:nth-child(2) {
    opacity: 0;
}

.hamburger.active .line:nth-child(3) {
    transform: rotate(-45deg) translate(7px, -7px);
}

/* Media Queries */
@media (max-width: 1024px) {
    .header {
        padding: 1rem 5%;
    }

    .nav-links {
        gap: 1rem;
    }

    .btn-primary {
        padding: 0.75rem 1rem;
        font-size: 0.8rem;
    }
}

/* Dodaj te style do istniejącego pliku Header.css */
@media (max-width: 768px) {

    .header {
        height: 70px; /* Mniejsza wysokość na telefonie */
        padding: 0.5rem 5%;
    }
    
    .logo {
        height: 65px; /* Mniejsze logo na telefonie */
    }

    .nav-menu {
        position: fixed;
        height: 100vh;
        width: 70%; /* Szerokość menu mobilnego */
        top: 0;
        right: -100%;
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: flex-start; /* Zmiana z center na flex-start */
        padding-top: 80px; /* Dodaj przestrzeń od góry */
        overflow-y: auto; /* Dodaj scroll jeśli potrzeba */
        transition: right 0.3s ease;
        z-index: 1000;
        box-shadow: -5px 0 15px rgba(0, 0, 0, 0.1);
    }
    
    .nav-menu.active {
        right: 0;
        width: 80%;
    }
    
    .nav-links {
        flex-direction: column;
        width: 100%;
        gap: 1rem;
        padding: 0 20px;
    }
    
    .mobile-menu-button {
        display: block;
        position: relative;
        z-index: 1001;
    }
    
  .btn-primary {
        margin: 20px auto;
        width: calc(99%);
    }
  
}

@media (max-width: 480px) {
    .header {
        height: 60px;
    }
    
    .logo {
        height: 40px;
    }
}