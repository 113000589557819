@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Montserrat+Narrow:wght@700&family=Open+Sans&display=swap');

.service {
    padding: 7rem 10%;
    text-align: center;
    background-color: #f9f9f9;
}

.service h2 {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    color: #333;
    font-family: 'Montserrat Narrow', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
}

.service > p {
    font-family: 'Open Sans', sans-serif;
    font-size: 1.1rem;
    line-height: 1.6;
    color: #555;
    margin-bottom: 3rem;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
}

.service-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    margin-top: 2rem;
}

.service-item {
    background: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.service-item:hover {
    transform: translateY(-10px);
}

.service-item img {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.service-content {
    padding: 1.5rem;
}

.service-content h3 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 1.25rem;
    color: #333;
    margin-bottom: 0.75rem;
    text-transform: uppercase;
}

.service-content p {
    font-family: 'Open Sans', sans-serif;
    font-size: 1rem;
    color: #666;
    line-height: 1.4;
}

@media (max-width: 992px) {
    .service-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

/* In Service.css - Enhance mobile appearance */
@media (max-width: 768px) {
    .service {
        padding: 5rem 1rem 3rem;
    }
    
    .service-item {
        margin-bottom: 1.5rem;
    }
    
    .service-item img {
        height: 180px;
    }
    .service-grid {
        grid-template-columns: 1fr; /* Zmiana z 2 na 1 kolumnę */
        gap: 1.5rem;
    }
    
    .service-item {
        margin-bottom: 1rem;
    }
}