@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Montserrat+Narrow:wght@700&family=Open+Sans&display=swap');

.realization {
    padding: 7rem 0;
    background-color: #f9f9f9;
}

.realization-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
}

.realization h2 {
    font-family: 'Montserrat Narrow', sans-serif;
    font-weight: 700;
    font-size: 2.5rem;
    color: #333;
    text-align: center;
    margin-bottom: 1.5rem;
    text-transform: uppercase;
}

.realization p {
    font-family: 'Open Sans', sans-serif;
    font-size: 1.1rem;
    line-height: 1.6;
    color: #555;
    text-align: center;
    margin-bottom: 3rem;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
}

.gallery-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1.5rem;
    padding: 1rem 0;
}

.gallery-item {
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
    aspect-ratio: 4/3;
}

.gallery-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.gallery-item-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.gallery-item-overlay span {
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-size: 0.9rem;
    text-align: center;
    padding: 1rem;
}

.gallery-item:hover img {
    transform: scale(1.05);
}

.gallery-item:hover .gallery-item-overlay {
    opacity: 1;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    padding: 2rem;
}

.modal-content {
    position: relative;
    max-width: 90%;
    max-height: 90vh;
}

.modal-content img {
    max-width: 100%;
    max-height: 90vh;
    object-fit: contain;
}

.close-button {
    position: absolute;
    top: -2rem;
    right: -2rem;
    color: white;
    font-size: 2rem;
    cursor: pointer;
    padding: 0.5rem;
}

/* In Realization.css - Better mobile gallery */
@media (max-width: 768px) {
    .gallery-grid {
        grid-template-columns: repeat(2, 1fr);
        gap: 0.5rem;
    }
    
    .modal-content {
        width: 95%;
    }
    
    .close-button {
        top: -1.5rem;
        right: 0;
    }
}

@media (max-width: 480px) {
    .gallery-grid {
        grid-template-columns: 1fr;
    }
}