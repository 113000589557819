@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Montserrat+Narrow:wght@700&family=Open+Sans&display=swap');

.hero {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-height: 60vh;
    padding: 3rem;
    background-image: linear-gradient(
        to right,
        rgba(255, 255, 255, 1) 50%,
        rgba(255, 255, 255, 0) 60%
    ), url('../components/photos_to_deploy/flatRoofMirror.jpg');
    background-size: contain;
    background-position: right ;
    background-repeat: no-repeat;
    position: relative;
    margin-top: 80px; /* Kompensacja dla fixed header */
}
.hero-content {
    max-width: 50%;
    padding: 2rem;
    margin-left: 10%;
    position: relative;
    z-index: 2;
}

.hero-content p {
    font-size: 1.5rem;
    color: #333;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 0.5rem;
}

.hero-content h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
    color: #333;
    font-family: 'Montserrat Narrow', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1.2;
}

.hero-buttons {
    display: flex;
    gap: 1rem;
    margin-top: 2rem;
}

.btn-primary, .btn-secondary {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.btn-primary {
    background-color: #ff5722;
    color: white;
}

.btn-primary:hover {
    background-color: #e64a19;
    transform: translateY(-2px);
}

.btn-secondary {
    background-color: transparent;
    color: #333;
    border: 2px solid #333;
}

.btn-secondary:hover {
    background-color: #333;
    color: white;
    transform: translateY(-2px);
}

/* Responsywność */
@media (max-width: 1024px) {
    .hero-content {
        max-width: 60%;
        margin-left: 5%;
    }
}

/* In HeroSection.css - Update the mobile media queries */
@media (max-width: 768px) {
    .hero {
        background-image: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0.9) 0%,
            rgba(255, 255, 255, 0.8) 100%
        ), url('../components/photos_to_deploy/flatRoofMirror.jpg');
        background-size: cover;
        background-position: center;
        min-height: 100vh;
        padding-top: 70px; /* Dodaj tę linię */
    }
    /* ... reszta istniejących stylów ... */

    .hero-content {
        max-width: 100%;
        margin-left: 0;
        text-align: center;
        padding-top: 30px;
        /* Account for fixed header */
    }
    
    .hero-buttons {
        flex-direction: column;
        align-items: center;
    }
    
    .hero-buttons button {
        width: 100%;
        margin-bottom: 10px;
    }
    .hero-content p {
        font-size: 1.2rem;
        margin-bottom: 1rem;
        display: block; /* Dodaj tę linię */
    }
    
    .hero-content h1 {
        font-size: 2rem;
        line-height: 1.3;
    }
}

@media (max-width: 480px) {
    .hero {
        min-height: 380px;
        padding: 1rem;
        background-position: 60% center; 
        padding-top: 60px;
    }
    
    .hero-content {
        padding: 1.2rem;
        backdrop-filter: none; /* Wyłączamy rozmycie na najmniejszych ekranach */
    }
    
    .hero-content h1 {
        font-size: 1.7rem;
        line-height: 1.3;
    }
    
    .hero-content p {
        font-size: 1rem;
    }
    
    .btn-primary, .btn-secondary {
        padding: 0.65rem 1rem; /* Mniejsze przyciski */
        font-size: 0.9rem;
    }
}