@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Montserrat+Narrow:wght@700&family=Open+Sans&display=swap');

.contact {
    background-color: #f9f9f9;
    padding: 5rem 0;
    width: 100%;
    overflow: hidden;
}

.contact-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 1.5rem;
    box-sizing: border-box;
}

.contact h2 {
    font-family: 'Montserrat Narrow', sans-serif;
    font-weight: 700;
    font-size: 2.2rem;
    color: #333;
    text-align: center;
    margin-bottom: 1.5rem;
    text-transform: uppercase;
    line-height: 1.3;
}

.contact-info {
    text-align: center;
    margin-bottom: 2rem;
}

.contact-info p {
    font-family: 'Open Sans', sans-serif;
    font-size: 1.1rem;
    color: #555;
    margin: 0.5rem 0;
}

.form-group {
    margin-bottom: 1.5rem;
}

.form-group input[type="text"],
.form-group input[type="email"],
.form-group textarea {
    width: 100%;
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-family: 'Open Sans', sans-serif;
    font-size: 1rem;
    transition: border-color 0.3s ease;
    box-sizing: border-box;
}

.form-group textarea {
    height: 150px;
    resize: vertical;
}

.form-group input:focus,
.form-group textarea:focus {
    outline: none;
    border-color: #ff5722;
}

.consent-group {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    text-align: left;
    margin-bottom: 1.5rem;
}

.consent-group input[type="checkbox"] {
    min-width: 18px;
    margin-top: 3px;
}

.consent-group label {
    font-family: 'Open Sans', sans-serif;
    font-size: 0.9rem;
    color: #666;
    line-height: 1.5;
}

button[type="submit"] {
    background-color: #ff5722;
    color: white;
    border: none;
    padding: 1rem 2rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 1rem;
    text-transform: uppercase;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 300px;
}

button[type="submit"]:hover {
    background-color: #e64a19;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

/* Responsywność */
@media (max-width: 768px) {
    .contact {
        padding: 3rem 0;
    }
    
    .contact-container {
        padding: 0 1rem;
    }
    
    .contact h2 {
        font-size: 1.8rem;
        margin-bottom: 1.2rem;
    }
    
    .contact-info p {
        font-size: 1rem;
    }
    
    .form-group input[type="text"],
    .form-group input[type="email"],
    .form-group textarea {
        padding: 0.8rem;
        font-size: 16px;
    }
    
    .consent-group {
        flex-direction: row;
        align-items: flex-start;
        gap: 0.8rem;
    }
    
    .consent-group label {
        font-size: 0.8rem;
        line-height: 1.4;
    }
    
    button[type="submit"] {
        padding: 0.9rem;
        max-width: 100%;
    }
}

@media (max-width: 480px) {
    .contact-container {
        padding: 0 0.8rem;
    }
    
    .contact h2 {
        font-size: 1.6rem;
    }
    
    .consent-group {
        flex-wrap: wrap;
    }
    
    .consent-group input[type="checkbox"] {
        margin-top: 5px;
    }
}